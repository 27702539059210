// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-alt-js": () => import("./../../../src/pages/about-alt-.js" /* webpackChunkName: "component---src-pages-about-alt-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-coaching-advisory-services-atlanta-js": () => import("./../../../src/pages/business-coaching-advisory-services-atlanta.js" /* webpackChunkName: "component---src-pages-business-coaching-advisory-services-atlanta-js" */),
  "component---src-pages-cyber-security-data-protection-atlanta-js": () => import("./../../../src/pages/cyber-security-data-protection-atlanta.js" /* webpackChunkName: "component---src-pages-cyber-security-data-protection-atlanta-js" */),
  "component---src-pages-financial-advisor-cpa-js": () => import("./../../../src/pages/financial-advisor-cpa.js" /* webpackChunkName: "component---src-pages-financial-advisor-cpa-js" */),
  "component---src-pages-index-1-js": () => import("./../../../src/pages/index-1.js" /* webpackChunkName: "component---src-pages-index-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payroll-management-services-atlanta-js": () => import("./../../../src/pages/payroll-management-services-atlanta.js" /* webpackChunkName: "component---src-pages-payroll-management-services-atlanta-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-review-us-js": () => import("./../../../src/pages/review-us.js" /* webpackChunkName: "component---src-pages-review-us-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-small-business-accounting-services-atlanta-js": () => import("./../../../src/pages/small-business-accounting-services-atlanta.js" /* webpackChunkName: "component---src-pages-small-business-accounting-services-atlanta-js" */),
  "component---src-pages-small-business-consulting-services-atlanta-js": () => import("./../../../src/pages/small-business-consulting-services-atlanta.js" /* webpackChunkName: "component---src-pages-small-business-consulting-services-atlanta-js" */),
  "component---src-pages-small-business-tax-preparation-services-atlanta-js": () => import("./../../../src/pages/small-business-tax-preparation-services-atlanta.js" /* webpackChunkName: "component---src-pages-small-business-tax-preparation-services-atlanta-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

